<template>
  <div class="page-box">
    <div class="btn-box">
      <a-button type="primary" @click="$router.push('/system/role_management/add/0')" v-pms="'add'">创建角色</a-button>
    </div>
    <base-table bordered :columns="columns" :dataSource="dataRef" :pagination="pageRtv" :rowKey="record => record.shopRoleId" :loading="loadingRef" @change="handleTableChange">
      <template slot="roleName" slot-scope="text, record">
        <div class='role-name-box'>
          <div>{{text}}</div>
          <div v-if="record.officialFlag" class='official-flag'>官</div>
        </div>
      </template>
      <div class='table-operations ' slot="operation" slot-scope="text, record">
        <a-button type='link' @click="$router.push('/system/role_management/detail/2/'+record.shopRoleId)">查看</a-button>
        <a-button type='link' v-if="!record.officialFlag || isPlatform" v-pms="'edit'" @click="$router.push('/system/role_management/edit/1/'+record.shopRoleId)">编辑</a-button>
        <a-popconfirm v-if="!record.officialFlag" placement="topRight" title="确定要删除？" ok-text="确定" cancel-text="取消" @confirm="()=>confirmDelete(record)">
          <a-button type='link' v-pms="'delete'">删除</a-button>
        </a-popconfirm>
      </div>
    </base-table>
  </div>
</template>

<script>
import { ref, onUnmounted } from '@vue/composition-api'
import { useTableList } from '@/hooks'
import { staff } from '@/api'
import { SHOPID } from '@/constants'
import { getSession } from '@/utils/session'
import { isPlatform } from '../../utils/tools'
const columns = [
  {
    title: '角色名称',
    dataIndex: 'roleName',
    scopedSlots: { customRender: 'roleName' }
  },
  {
    title: '角色描述',
    dataIndex: 'roleDesc'
  },
  {
    title: '员工数量',
    dataIndex: 'staffNum'
  },
  {
    title: '操作',
    width: 300,
    dataIndex: 'operation',
    scopedSlots: { customRender: 'operation' }
  }
]
export default {
  name: 'PageRoleManagement',
  setup (props, { root }) {
    const callback = () => {
      refresh()
    }
    root.$bus.$on('PageRoleList:refresh', callback)
    onUnmounted(() => {
      root.$bus.$off('PageRoleList:refresh', callback)
    })
    const params = ref({
      shopId: getSession(SHOPID)
    })
    const { dataRef, pageRtv, loadingRef, setPage, refresh } = useTableList(
      staff.getRoleList,
      params
    )
    function handleTableChange ({ current }) {
      setPage(current)
    }
    async function confirmDelete ({ staffNum, shopRoleId }) {
      if (staffNum > 0) return this.$message.error('该角色下有员工存在，不能删除')
      let { code, msg } = await staff.deleteRole({
        shopRoleId: shopRoleId
      })
      if (code === '00000') {
        root.$message.success('删除成功')
        refresh()
      } else {
        root.$message.error(msg || '网络错误，请重试～')
      }
    }

    return {
      isPlatform,
      currentShopId: getSession(SHOPID),
      refresh,
      params,
      columns,
      dataRef,
      pageRtv,
      loadingRef,
      confirmDelete,
      handleTableChange
    }
  }
}
</script>

<style lang="less" scoped>
.role-name-box {
  display: flex;
  align-items: center;
  .official-flag {
    background: @main-color;
    color: #fff;
    padding: 4px;
    font-size: 12px;
    line-height: 12px;
    border-radius: 2px;
    margin-left: 6px;
    font-weight: bolder;
  }
}
.btn-box {
  margin-bottom: 18px;
  ::v-deep .ant-btn {
    margin-right: 12px;
    &:last-child {
      margin: 0;
    }
  }
}
</style>
